
<template>
  <!-- // <template>
//   <div class="blog">
//     <h1>This is an blog page</h1>
//   </div>
  // </template>-->
  <v-card flat tile>
    <v-container class="lighten-4" fluid>
      <v-row class="justify-center">
        <v-col cols="10" class="text-left">
          <h1>{{content.title}}</h1>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col
          v-for="(post, postId) in posts"
          :key="postId"
          class="justify-center pl10 pr10"
          cols="10"
          sm="10"
          md="10"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 20 : 2" :href="post.link">
              <v-img class="imgRotate" :src="post.images[0]" height="300px"></v-img>

              <v-card-title class="white justify-right">
                <span class="headline black--text pl-4 pt-4" v-text="post.title"></span>
              </v-card-title>
              <v-card-subtitle class="white text-left">
                <span class="pl-4 black--text pt-4">{{ convertTime(post.updateTime)}}</span>
              </v-card-subtitle>
              <div class="white text-left pl-4">
                <v-card-text>
                  <span class="black--text pl-4 pt-4" v-text="trimmedText(post.content)"></span>
                </v-card-text>
              </div>
              <!-- <v-card-text class="white text-left">
              <span class="pl-4 black--text pt-4">HK$</span>
              <span class="pl-4 black--text pt-4" v-text="post.price"></span>
              </v-card-text>-->
              <!-- <v-card-actions class="white justify-center">
                <v-btn
                  v-for="(social, i) in socials"
                  :key="i"
                  :color="social.color"
                  class="red--text"
                  :href="social.link + encodeURI( watch.name )"
                  fab
                  icon
                  small
                >
                  <v-icon>{{ social.icon }}</v-icon>
                </v-btn>

              </v-card-actions>-->
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import firebase from '@/fb'
var db = firebase.firestore()
// var storage = firebase.storage()

export default {
  data () {
    return {
      content: {},
      socials: [
        {
          icon: 'fab fa-facebook',
          color: 'indigo'
        },
        {
          icon: 'fab fa-instagram',
          color: 'red lighten-3'
        },
        {
          icon: 'fab fa-whatsapp',
          color: 'green lighten-3',
          link: 'https://wa.me/85268819431/?text=' + encodeURI('我想揾一隻適合我嘅手錶!')
        }
      ],
      posts: []
    }
  },

  created () {
    db.collection('posts')
      .orderBy('updateTime', 'desc')
      .limit(15)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // console.log('doc.data(): ')
          // console.log(doc.data())
          this.posts.push(doc.data())
          // console.log(`${doc.id} => ${doc.data()}`)
        })
      })
    // console.log(this.posts)
  },
  methods: {
    trimmedText (content) {
      return content.length > 100
        ? content.substring(0, 100 - 3) + '...'
        : content
    },
    convertTime (timeStamp) {
      // console.log(timeStamp)
      // console.log(timeStamp instanceof Date)

      // console.log(timeStamp && timeStamp.toDate())
      // console.log((Timestamp)timeStamp )

      // console.log(timeStamp.toDate().toISOString())
      // console.log(
      //   timeStamp
      //     .toDate()
      //     .toISOString()
      //     .slice(0, 10)
      // )
      if (timeStamp && timeStamp.toDate()) {
        return timeStamp
          .toDate()
          .toISOString()
          .slice(0, 10)
      }
      return timeStamp
    }
  },
  mounted () {
    if (localStorage.language !== 'en') {
      this.content = {
        title: '玩錶心得'
      }
    } else {
      this.content = {
        title: 'Blogs'
      }
    }
  }
}
</script>
<style scoped>
.imgRotate {
  /* transform:rotate(90deg); */
  image-orientation: from-image;
}
</style>
